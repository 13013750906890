import React from 'react';
import SectionTitle from '../../sectionTitle';
import styled from 'styled-components';
import DocItem from '../../../components/Downloads/DocItem';
import Witness from './Witness';
import { Link } from 'gatsby';

const LinkButton = styled.button`
  background: linear-gradient(320.07deg, #2f8975 24.52%, #0078a3 72.78%);
  border-radius: 50px;
  color: white;
  min-width: 6rem;
  width: 20rem;
  margin: 0 auto;
  padding: 8px 40px;
  margin-top: 1.5rem;
`;

const docs = require('../../../HomeDocItems.json');

const Section = () => {
  return (
    <div className="wrapper">
      <div className="-lg:px-16 sm:mt-10 md:pb-64 flex flex-col w-full h-full px-3 pt-12 pb-40">
        <SectionTitle title="Télécharger les fiches d'Autocontrôle et de Réception" />
        <div className="flex flex-row flex-wrap w-full">
          {docs.map((doc, index) => (
            <div key={index} className="sm:w-full flex justify-center w-1/2">
              <DocItem cate={doc.cate} icon={doc.icon} title={doc.title} url={doc.url} width="97%" />
            </div>
          ))}
        </div>
        <Link to="/download">
          <LinkButton className="flex items-center justify-between max-w-full text-xl">
            Voir toutes les fiches <span className="text-3xl">{'>'}</span>
          </LinkButton>
        </Link>
        {/* <div className="md:flex-col -md:px-12 -md:mt-8 flex flex-row justify-between w-full">
          <Witness
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
            job="builder"
            name="Nicolas Durand"
          />
          <Witness
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
            name="Jean Dupond"
            job="architect"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Section;
