import React from 'react';
import styled from 'styled-components';
import Box from '../../../images/cardsLogos/box.svg';
import Check from '../../../images/cardsLogos/check.svg';

const gradient = `linear-gradient(320.07deg, #2f8975 24.52%, #0078a3 72.78%)`;
const shadow = `drop-shadow(0 0.2rem 0.75rem rgba(147, 150, 152, 0.3))`;

const CardItem = styled.div`
  filter: ${shadow};
  min-width: 21rem;
  max-width: 28rem;
  width: 40%;
  @media (max-width: 902px) {
    width: 90%;
    min-width: unset;
  }
`;

const TitleCard = styled.h3`
  background: ${gradient};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.15rem;
  line-height: 2.25rem;
  font-weight: 600;
`;

const Card = (props) => (
  <CardItem className="card sm:max-w-full -lg:w-1/3 -md:mx-6 z-10 flex flex-col items-center justify-start p-6 my-8 mt-6 bg-white rounded">
    <img src={choose(props.src)} className="cardToken sm:w-14 absolute" alt="vectorCard" />
    <TitleCard> {props.title}</TitleCard>
    <hr className="w-11/12 my-6" />
    <p className="md:text-base text-lg">
      {props.textOne}
      <br />
      <br />
      {props.textTwo}
    </p>
  </CardItem>
);

const choose = (src) => (src === 'A' ? Check : Box);

export default Card;
