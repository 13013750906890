import React from 'react';
import styled from 'styled-components';
import CheckReno from '../../../images/SectionOne/typologo.svg';
import Check from '../../../images/check.svg';

const SiteTitle = styled.span`
  font-size: x-large;
  font-weight: 800;
  color: rgba(0, 119, 163, 1);
  font-size: 2rem;
  @media (max-width: 1023px) {
    font-size: 1.5rem;
  }
  @media (min-width: 1523px) {
    font-size: 2.5rem;
  }
`;
const SiteTitleBis = styled(SiteTitle)`
  color: black;
`;

const bgGradient = `linear-gradient(320.07deg, #2F8975 24.52%, #0078A3 72.78%)`;

const Gradient = styled.div`
  background: ${bgGradient};
`;

const SectionOne = () => (
  <>
    <Gradient className="-md:px-16 md:mt-28 -lg:mt-28 flex-nowrap md:flex-col z-10 flex flex-row justify-center w-full p-6 py-12 mt-40 text-white">
      <div className="md:w-full flex flex-col w-1/2">
        <div className="w-1/2 h-auto">
          <img src={CheckReno} className="object-contain" />
        </div>
        <p className="-md:w-3/4 my-3 text-lg font-bold">
          Votre application pour piloter en continu la qualité sur vos chantiers de rénovation énergétique
        </p>
        <ul className="SectionList / mt-5">
          <li class="flex flex-nowrap">
            <img src={Check} class="w-6 h-auto mr-3" />
            <span> Gérez et contrôlez facilement vos interventions sur le terrain</span>
          </li>
          <li class="flex flex-nowrap my-3">
            <img src={Check} class="w-6 h-auto mr-3" />
            <span> Préparez en confiance la réception des travaux</span>
          </li>
          <li class="flex flex-nowrap items-center">
            <img src={Check} class="w-6 h-auto mr-3" />
            <span> Sécurisez la qualité de vos travaux et la satisfaction de vos clients</span>
          </li>
        </ul>
      </div>
      <div className="md:w-full md:mt-5 w-1/2">
        <iframe
          height="315"
          src="https://www.youtube.com/embed/B6mxxbFyM8E"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Gradient>
  </>
);

export default SectionOne;
