import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SectionOne from '../components/Sections/SectionOne/Section';
import SectionTwo from '../components/Sections/SectionTwo/Section';
// import SectionThree from '../components/Sections/SectionThree/Section';
// import SectionFour from '../components/Sections/SectionFour/Section';
import DownloadSection from '../components/Sections/DownloadSection/Section';

const IndexPage = () => (
  <Layout>
    <SEO title="CHECK'RENO - Profeel" />
    <SectionOne className=" -lg:px-16 pb-28 px-3" />
    <SectionTwo />
    {/* <SectionThree /> */}
    <DownloadSection />
    {/* <SectionFour /> */}
  </Layout>
);

export default IndexPage;
