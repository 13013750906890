import React from 'react';
import styled from 'styled-components';

// pics & components imports
import SectionTitle from '../../sectionTitle';
import AppleStore from '../../../images/SectionTwo/AppleStore.svg';
import WindowsStore from '../../../images/SectionTwo/WindowsStore.png';
import ScreensPreviews from '../../../images/SectionTwo/ScreensPreviews.png';
import CAPEB from '../../../images/CAPEB.jpg';
import FFB from '../../../images/FFB.jpg';
import Card from './Card';
import Builders from '../../../images/builders.png';

const bgGradient = `linear-gradient(320.07deg, #2F8975 24.52%, #0078A3 72.78%)`;

const StoreTitle = styled.h3`
  background: ${bgGradient};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.15rem;
  line-height: 2.25rem;
  font-weight: 800;
  @media (max-width: 550px) {
    padding: 0 0.75rem;
    font-size: 1rem;
  }
`;

const MainText = styled.h3`
  background: ${bgGradient};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
  line-height: 1.55rem;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

const SectionTwo = () => (
  <>
    <div className="left-0 w-full max-w-full">
      <div className="flex-nowrap z-10 flex flex-row max-w-full">
        <div className="md:w-full md:flex-col flex-crow flex items-center justify-center w-full h-full pt-6">
          <div className="graywrapper md:w-full flex flex-col items-center w-1/2">
            <div className="sm:flex-col flex flex-col-reverse items-center justify-between w-full pb-6">
              <div className="p-2 bg-white rounded-md">
                <StoreTitle>Disponible sur Google Play et l’App Store  </StoreTitle>
              </div>
              <img src={ScreensPreviews} alt="screens" />
            </div>
            <div className="sm:w-3/4 flex flex-row justify-between w-3/4">
              <a href="https://apps.apple.com/app/id1538147023" target="_blank">
                <img src={AppleStore} alt="applestore mr-2" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.qualiteconstruction.checkreno" target="_blank">
                <img src={WindowsStore} alt="windowsstore" />
              </a>
            </div>
          </div>
          <div className="-md:px-16 md:my-4 md:w-full flex flex-col items-center justify-between w-1/2 h-full px-3 bg-white">
            <div className="flex flex-col justify-between">
              <SectionTitle
                title="Un outil simple et intuitif  
destiné aux artisans 
et entreprises du Bâtiment"
              />
              <p className="pb-5 mt-3">
                Cet outil gratuit a été développé collectivement par l’Agence qualité construction (AQC) en
                lien étroit avec la Confédération de l'Artisanat et des Petites Entreprises du Bâtiment
                (CAPEB) et la Fédération Française du Bâtiment (FFB) dans le cadre du programme PROFEEL.{' '}
              </p>
            </div>

            <div className=" flex flex-row items-center justify-between w-1/2">
              <div className=" w-1/4">
                <a target="_blank" href="https://www.capeb.fr">
                  <img src={CAPEB} className="object-contain" />
                </a>
              </div>
              <div className=" w-1/4">
                <a target="_blank" href="https://ffbatiment.fr">
                  <img src={FFB} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={Builders} className="w-full my-12" />
      <div className="-md:px-12 cardWrapper -lg:justify-start flex flex-row flex-wrap justify-center my-12">
        <Card
          title="L’AUTOCONTRÔLE AVEC CHECK’RÉNO"
          textOne="Check’Réno vous facilite l’autocontrôle de vos interventions à toutes les étapes de vos chantiers."
          textTwo="Retrouvez des listes de points de vérifications pour chaque lot de travaux.  
Vous pouvez modifier ces listes pour les adapter à vos besoins et/ou créer vos propres listes"
          src="A"
        />
        <Card
          className="sm:mb-40"
          title="LA RÉCEPTION AVEC CHECK’RÉNO"
          textOne="Check’Réno vous accompagne lors de la réception de travaux avec vos clients. Il vous permet de pointer les points les plus importants sur les travaux réalisés."
          textTwo="Construit comme un outil de dialogue, l’objectif est de consolider la relation de confiance entre vous et vos clients, et vous permettra également la génération et la signature d’un procès-verbal de réception."
          src="C"
        />
      </div>
    </div>
  </>
);

export default SectionTwo;
